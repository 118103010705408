<template>
  <div class="container">
    <PostersCarousel :posters="posters"></PostersCarousel>
  </div>
</template>

<script>
import PostersCarousel from '@/components/PostersCarousel.vue';
import posters from '@/assets/parties.json'

export default {
  components: {
    PostersCarousel
  },
  data () {
    return {
      posters: posters.parties
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 5rem;
  }
</style>